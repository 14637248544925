.app{
    scroll-behavior: smooth;
    transition: all ease 0.5s;
    background-color:rgb(229, 231, 235) ;
}/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    
    background-color:rgb(238, 238, 238) ;

  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #fb923c; 
  
  }