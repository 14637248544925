@tailwind base;
@tailwind components;
@tailwind utilities;@import "~react-image-gallery/styles/css/image-gallery.css";
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Pacifico&family=Poppins:wght@300;400;500;600;700;800;900&family=Questrial&family=Raleway&family=Satisfy&display=swap');
body {
  margin: 0;
  font-family: 'Questrial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(238, 238, 238);
}
:root {
	--background: #fe904c;
	--primary: #ffffff;
	--secondary: #f78138;
	--third: #ffffff;
  /* --------- */
	
}
.TF{
  font-family: 'Questrial', sans-serif;

}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.TF{
  font-family: 'Questrial', sans-serif;}
.active{
box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  background-color: #fe904c;
  color:#3a3138 ;
  animation: morph 8s ease-in-out infinite;
  border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;} 

  @keyframes morph {
    0% {
        border-radius:  60% 40% 30% 70% / 60% 30% 70% 40%;
        background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    } 
    
    50% {
        border-radius:  30% 60% 70% 40% / 50% 60% 30% 60%;
        background: linear-gradient(45deg, var(--third) 0%, var(--secondary) 100%);
    }
    
    100% {
      border-radius:  60% 40% 30% 70% / 60% 30% 70% 40%;
      background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    } 
  }
  
  .serviceBTN{
    font-weight: 700;
    text-align: center;
    font-size: 40px;
    font-family: Hack, sans-serif;
    text-transform: uppercase;
    background: linear-gradient(90deg, #fb923c, #fff, #514e4d);
    letter-spacing: 5px;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-repeat: no-repeat;
    background-size: 80%;
    animation: shine 3s linear infinite;
    position: relative;
  }
  @keyframes shine {
    0% {
      background-position-x: -500%;
    }
    100% {
      background-position-x: 500%;
    }
  }
